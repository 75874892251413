<script setup>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import { ElButton } from "element-plus";
import { computed, onMounted, ref, defineProps, watch } from "vue";
import Layout2ContentCard from "./partials/Layout2ContentCard.vue";
import LoadingSpinner from "@/components/utilities/LoadingSpinner.vue";
import { useLayout2HomeFiltersStore } from "../../stores/useLayout2HomeFilters.store";
import { storeToRefs } from "pinia";

const props = defineProps({
  title: {
    type: String,
  },
  contents: {
    type: Array,
    required: true,
    default: () => [],
  },
  isAssign: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  emptyText: {
    type: String,
    default: "No Available Contents",
  },
});

const layout2HomeFiltersStore = useLayout2HomeFiltersStore();
const { selectedFilter } = storeToRefs(layout2HomeFiltersStore);

const mainRef = ref();
const scrollableDiv = ref();

const maxWidth = computed(() => mainRef.value?.offsetWidth);
const title = computed(() => props.title);
const contents = computed(() => props.contents);
const loadingList = computed(() => props.loading);
const emptyText = computed(() => props.emptyText);

const handleClickScroll = (isRight = false) => {
  if (scrollableDiv.value) {
    scrollableDiv.value.scrollBy({
      left: isRight ? 500 : -500,
      behavior: "smooth",
    });
  }
};

watch(selectedFilter, () => {
  if (scrollableDiv.value) {
    scrollableDiv.value.scrollTo({
      left: 0,
      behavior: "smooth",
    });
  }
});

onMounted(() => {
  //
  contents.value;
  loadingList.value;
});
</script>
<template>
  <div class="flex grow flex-col" ref="mainRef">
    <div v-if="maxWidth" class="flex-1 flex flex-col gap-2">
      <div class="flex w-full gap-2">
        <div class="flex-1 flex justify-start items-center">
          <h1 class="text-lg font-semibold text-untitled-gray-600">
            {{ title }}
          </h1>
        </div>
        <div class="flex justify-center items-center">
          <ElButton
            :icon="ChevronLeftIcon"
            @click="handleClickScroll(false)"
          ></ElButton>
          <ElButton
            :icon="ChevronRightIcon"
            @click="handleClickScroll(true)"
          ></ElButton>
        </div>
      </div>
      <div
        class="overflow-x-auto overflow-hidden flex py-1 gap-2 md:gap-4 scroll-smooth home-scrollable-container px-2 rounded-md"
        ref="scrollableDiv"
        v-if="mainRef && !loading"
      >
        <Layout2ContentCard
          v-for="content in contents"
          :key="content.id"
          :content="content"
          :isAssign="isAssign"
        />
      </div>
      <div
        class="w-full flex justify-center items-center text-untitled-gray-400 p-4 border border-untitled-gray-200 rounded-md"
        v-if="contents.length === 0 && !loading"
      >
        <h1 class="font-semibold text-sm">
          {{ emptyText }}
        </h1>
      </div>
      <div
        class="overflow-x-auto overflow-hidden flex py-1 gap-2 md:gap-4 scroll-smooth home-scrollable-container px-2 rounded-md"
        v-if="loading"
      >
        <div
          class="w-80 min-w-80 h-72 shadow border-untitled-gray-200 flex flex-col rounded-md relative"
          v-for="n in 10"
          :key="n"
        >
          <div
            class="w-full flex h-48 relative bg-untitled-gray-300 animate-pulse justify-center items-center"
          >
            <LoadingSpinner />
          </div>
          <div class="flex flex-col p-2 gap-3">
            <div class="flex justify-between gap-2">
              <div
                class="w-1/3 p-2 bg-untitled-gray-300 animate-pulse rounded-md"
              ></div>
              <div
                class="w-1/3 p-2 bg-untitled-gray-300 animate-pulse rounded-md"
              ></div>
            </div>
            <div
              class="w-2/4 p-2 animate-pulse bg-untitled-gray-300 rounded-md"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
